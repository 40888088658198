import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { API, ENVIRONMENT } from 'src/app/common/restapi';
import { catchError, map } from "rxjs/operators";
import { AuthService } from 'src/app/http-interceptors/authentication/auth.service';
import { Router } from '@angular/router';


@Injectable({providedIn: 'root' })
export class RealtorsService {
  
  private baseUrl: string;
  headers: HttpHeaders;
  apiResult: Object;
  constructor(private http: HttpClient, private authService: AuthService, private router: Router,) {
    this.baseUrl = ENVIRONMENT.BASEURL;
  }

  // ---------------------- for Realtors List api start------------------------------------

  realtorsListApi(data: any) {
    let payload = data;
    this.headers = this.authService.setHeader();
    return this.http.post(this.baseUrl + `${API.REALTOR_LIST}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
  // ----------------------   for Realtors List api end   --------------------------------

  // ---------------------- for Realtors Details api start------------------------------------

  viewDetailsApi(data: any) {
    let payload = data;
    this.headers = this.authService.setHeader();
    return this.http.post(this.baseUrl + `${API.VIEW_DETAILS}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
  // ----------------------   for Realtors Details api end   --------------------------------

  // ---------------------- for Add Realtors api start------------------------------------
  addRealtorApi(data: any) {
    let payload = data;
    this.headers = this.authService.setHeader();
    return this.http.post(this.baseUrl + `${API.ADD_REALTOR}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
  // ----------------------   for Add Realtors api end   --------------------------------

  // ---------------------- for Edit Realtors api start------------------------------------
  editRealtorApi(data: any) {
    let payload = data;
    this.headers = this.authService.setHeader();
    return this.http.post(this.baseUrl + `${API.UPDATE_LIST}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
  // ----------------------   for Edit Realtors api end   --------------------------------

  // ---------------------- for FRANCHISE_LIST api start------------------------------------
  franchiseListApi(data: any) {
    let payload = data;
    this.headers = this.authService.setHeader();
    return this.http.post(this.baseUrl + `${API.FRANCHISE_LIST}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
  // ----------------------   for FRANCHISE_LIST api end   --------------------------------

  



}
