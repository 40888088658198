import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { API, ENVIRONMENT } from 'src/app/common/restapi';
import { catchError, map } from "rxjs/operators";
import { AuthService } from 'src/app/http-interceptors/authentication/auth.service';
import { Router } from '@angular/router';


@Injectable({providedIn: 'root' })
export class AdminstrationService {
  
  private baseUrl: string;
  headers: HttpHeaders;
  apiResult: Object;
  constructor(private http: HttpClient, private authService: AuthService, private router: Router,) {
    this.baseUrl = ENVIRONMENT.BASEURL;
  }

  // ---------------------- for adminstration List api start------------------------------------

  administrationListApi(data: any) {
    let payload = data;
    this.headers = this.authService.setHeader();
    return this.http.post(this.baseUrl + `${API.LIST_ADMINISTRATOR}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
  // ----------------------   for adminstration List api end   --------------------------------

  // --------------------- for add adminstration getRoll api start-------------------------------
  roolListApi() {
    let payload = "";
    this.headers = this.authService.setHeader();
    console.log("this header is",this.headers);
    return this.http.post(this.baseUrl + `${API.GET_ROLL}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
  // --------------------for add adminstration getRoll api end   --------------------------------

  // --------------------- for add adminstration getRoll api start-------------------------------
  addAdministrationApi(data: any) {
    let payload = data;
    this.headers = this.authService.setHeader();
    console.log("this header is",this.headers);
    return this.http.post(this.baseUrl + `${API.ADD_ADMINISTRATOR}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
  // ------------------- for add adminstration getRoll api end   --------------------------------

  // --------------------- for delete user api start-------------------------------
  deleteuserApi(data: any) {
    let payload = data;
    this.headers = this.authService.setHeader();
    console.log("this header is",this.headers);
    return this.http.post(this.baseUrl + `${API.DELETE_ADMINISTRATOR}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
  // ------------------- for delete user api end   --------------------------------

   // --------------------- for active user api start-------------------------------
   activeuserApi(data: any) {
    let payload = data;
    this.headers = this.authService.setHeader();
    console.log("this header is",this.headers);
    return this.http.post(this.baseUrl + `${API.ACTIVE_ADMINISTRATOR}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
  // ------------------- for active user api end   --------------------------------

  // --------------------- for block user api start-------------------------------
    blockuserApi(data: any) {
    let payload = data;
    this.headers = this.authService.setHeader();
    console.log("this header is",this.headers);
    return this.http.post(this.baseUrl + `${API.BLOCK_ADMINISTRATOR}`, payload, { headers: this.headers})
    .pipe(map(res => res),
    catchError(this.authService.handleError)
    );
  }
// ------------------- for block user api end   --------------------------------
  
// --------------------- for edit user api start-------------------------------
edituserApi(data: any) {
  let payload = data;
  this.headers = this.authService.setHeader();
  console.log("this header is",this.headers);
  return this.http.post(this.baseUrl + `${API.EDIT_ADMINISTRATOR}`, payload, { headers: this.headers})
  .pipe(map(res => res),
  catchError(this.authService.handleError)
  );
}
// ------------------- for edit user api end   --------------------------------
  
// --------------------- for set Password api start-------------------------------
setPassApi(data: any) {
  let payload = data;
  this.headers = this.authService.setHeader();
  console.log("this header is",this.headers);
  return this.http.post(this.baseUrl + `${API.SET_PASSWORD}`, payload, { headers: this.headers})
  .pipe(map(res => res),
  catchError(this.authService.handleError)
  );
}
// ------------------- for set Password api end   --------------------------------
  
// --------------------- for set Password api start-------------------------------
getPassSettingApi(data: any) {
  let payload = data;
  this.headers = this.authService.setHeader();
  console.log("this header is",this.headers);
  return this.http.post(this.baseUrl + `${API.GET_PASSWORD_SETTING}`, payload, { headers: this.headers})
  .pipe(map(res => res),
  catchError(this.authService.handleError)
  );
}
// ------------------- for set Password api end   --------------------------------
  
// for changePassword api start
changePasswordApi(data: any): Observable<any> {
  console.log(this.headers);
  return this.http.post(this.baseUrl + `${API.CHANGE_PASSWORD}`, data).pipe(
    map(res => res),
    catchError(this.authService.handleError)
  );
}
// for changePassword api end



}
