import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject } from "rxjs";
import { API, ENVIRONMENT } from "../../common/restapi";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../../http-interceptors/authentication/auth.service";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private dataSource = new BehaviorSubject(null);
  currentData = this.dataSource.asObservable();
  constructor(private http: HttpClient, private authService: AuthService) { }

  // for HomeData api start
  addCat(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8080/api/categoryrules/createcategoryrules`, data).pipe(
    return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.CATEGORY_RULE}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }

  // for HomeData api start
  editCat(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8080/api/categoryrules/editcategoryrules`, data).pipe(
    return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.EDIT_CATEGORYRULES}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }
  homefilterListApi(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8083/api/user/filter/homefilterList`, data).pipe(
      return this.http.post(ENVIRONMENT.BASEURL_USER_SERVICE1 + `${API.HOMEFILTER_LIST}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }

  homeWorthFilterListApi(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8083/api/user/filter/homeWarthFilterList`, data).pipe(
      return this.http.post(ENVIRONMENT.BASEURL_USER_SERVICE1 + `${API.HOME_WORTH_FILTER_LIST_IS}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }

  homeWorthFilterApi(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8083/api/user/filter/homeWarthFilter`, data).pipe(
      return this.http.post(ENVIRONMENT.BASEURL_USER_SERVICE1 + `${API.HOMEWorth_FILTER_IS}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }


  homefilterApi(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8083/api/user/filter/homefilter`, data).pipe(
    // return this.http.post(`http://172.29.2.79:8083/api/user/filter/homewarthfilterr`, data).pipe(
    return this.http.post(ENVIRONMENT.BASEURL_USER_SERVICE1 + `${API.HOME_WORTH_FILTER}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }

  // homefilterApi(data: any): Observable<any> {
  //   // return this.http.post(`http://172.29.2.79:8083/api/user/filter/homewarthfilterr`, data).pipe(
  //   return this.http.post(ENVIRONMENT.BASEURL_USER_SERVICE1 + `${API.HOME_WORTH_FILTER}`, data).pipe(
  //     map(res => res),
  //     catchError(this.authService.handleError)
  //   );
  // }

  getCategory(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8080/api/categoryrules/getcategoryruleslist`, data).pipe(
    return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.CATEGORY_RULE_LIST}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }

  deleteCategory(data: any): Observable<any> {
    return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.CATEGORY_DELETE}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }


  editData(value: any) {
    this.dataSource.next(value);
  }

  getEditCategory(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8080/api/categoryrules/getcategoryrules`, data).pipe(
    return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.EDIT_CATEGORY}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }

  saveCalWorthHomeApi(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8080/api/homewarth/savehomewarthalog`, data).pipe(
    return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.ADD_CAL_WORTH_HOME}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }

  publishCalWorthHomeApi(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8080/api/homewarth/getpublishedhomewarth`, data).pipe(
    return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.PUBLISH_CAL_WORTH_HOME}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }

  calWorthHomeApi(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8080/api/homewarth/publishedHomeAlgo`, data).pipe(
    return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.PUBLISH_CAL_WORTH_HOME_ALGO}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }

  calWorthHomeAlgoApi(data: any): Observable<any> {
    // return this.http.post(`http://172.29.2.79:8083/api/configs/gethomewarth`, data).pipe(
    return this.http.post(ENVIRONMENT.BASEURL_USER_SERVICE2 + `${API.GET_CAL_WORTH_HOME}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }


}
