import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject } from "rxjs";
import { API, ENVIRONMENT } from "../../common/restapi";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../../http-interceptors/authentication/auth.service";
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class UpcomingPropertyService {
  private dataSource = new BehaviorSubject(null);
  currentData = this.dataSource.asObservable();

  private baseUrl: string;
  baseUrl_ADMIN: string;
  baseUrl_IMAGE: string;
  headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.baseUrl = ENVIRONMENT.BASEURL;
    this.baseUrl_ADMIN = ENVIRONMENT.BASEURL_ADMIN;
    this.baseUrl_IMAGE = ENVIRONMENT.BASEURL_UPLOAD_IMAGE;
  }

  // private extractData(res: Response) {
  //   const body = res.json();
  //   return body || {};
  // }

  // // for navigation api start
  // mlsListing(data: any): Observable<any> {
  //   return this.http.post(this.baseUrl + `${API.MLS_LISTING}`, data).pipe(
  //     map(res => res),
  //     catchError(this.authService.handleError)
  //   );
  // }
  // // for navigation api end

  // // for navigation api start
  // mlsCongifAPI(data: any): Observable<any> {
  //   return this.http.post(this.baseUrl + `${API.MLS_CONFIG}`, data).pipe(
  //     map(res => res),
  //     catchError(this.authService.handleError)
  //   );
  // }
  // for navigation api end

  // for add property api start
  AddPropertyAPI(data: any): Observable<any> {
    return this.http.post(this.baseUrl + `${API.ADD_PROPERTY}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }
  // for add property api api end

  // for Edit property api start
  EidtPropertyAPI(data: any): Observable<any> {
    return this.http.post(this.baseUrl + `${API.EDIT_PROPERTY}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }
  // for add property api api end

  // for delete property api start
  DelPropertyAPI(data: any): Observable<any> {
    return this.http.post(this.baseUrl + `${API.DEL_PROPERTY}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }
  // for delete property api api end

  // for navigation api start
  upcomingListing(data: any): Observable<any> {
    return this.http.post(this.baseUrl + `${API.LIST_PROPERTY}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }
  // for navigation api end
  uploadImage(data: any) {
    return this.http.post(this.baseUrl + `${API.IMAGE_PROPERTY}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }


  //method for Sharing Data
  getListData(value: any) {
    this.dataSource.next(value);
  }
  getHomeList() {
    // this.headers = this.authService.setHeaderWithoutToken();
    return this.http.post(this.baseUrl_ADMIN + `${API.GET_HOME_LIST}`, {})
      .pipe(map(res => res),
        catchError(this.authService.handleError)
      );
  }

  getProvinceList() {
    return this.http.post(this.baseUrl_ADMIN + `${API.GET_PROVINCE_LIST}`, {})
      .pipe(map(res => res),
        catchError(this.authService.handleError)
      );
  }

  getSubLocationByProvince(data: any) {
    return this.http.post(this.baseUrl_ADMIN + `${API.GET_SUB_LOCATION}`, data)
      .pipe(map(res => res),
        catchError(this.authService.handleError)
      );
  }

  uploadImageMultipart(data: any) {
    return this.http.post(ENVIRONMENT.BASEURL_UPLOAD_IMAGE + `${API.IMAGE_PROPERTY_MULTIPART}`, data)
      .pipe(map(res => res),
        catchError(this.authService.handleError)
      );
  }

  deleteImageApi(data: any) {
    return this.http.post(ENVIRONMENT.BASEURL_UPLOAD_IMAGE + `${API.DELETE_IMAGE}`, data)
      .pipe(map(res => res),
        catchError(this.authService.handleError)
      );
  }



}
