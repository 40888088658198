import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { API, ENVIRONMENT } from "../../common/restapi";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../../http-interceptors/authentication/auth.service";
import Swal from 'sweetalert2';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class MlsListingService {

  private baseUrl: string;
  private baseUrl_IMAGE: string;
  private baseUrl_ADMIN: string;
  headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.baseUrl = ENVIRONMENT.BASEURL;
    this.baseUrl_IMAGE = ENVIRONMENT.BASEURL_UPLOAD_IMAGE;
    this.baseUrl_ADMIN = ENVIRONMENT.BASEURL_ADMIN;
    this.baseUrl = ENVIRONMENT.BASEURL;
  }

  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }

  // for navigation api start
  mlsListing(data: any): Observable<any> {
    return this.http.post(this.baseUrl + `${API.MLS_LISTING}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }
  // for navigation api end

  // for navigation api start
  mlsCongifAPI(data: any): Observable<any> {
    this.headers = this.authService.setHeader();
    return this.http.post(this.baseUrl + `${API.MLS_CONFIG}`, data, { headers: this.headers}).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }
  // for navigation api end


  ///// Service Method for Add Blogs ///////
  mls_categoryList() {
    // return this.http.post(this.baseUrl + `${API.GET_CATEGORY_LIST}`, {}).pipe(
    return this.http.post(this.baseUrl + `${API.GET_CATEGORY_LIST}`, {}).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }

  getPublishBlog(data: any) {
    return this.http.post(this.baseUrl + `${API.PUBLISH_BLOG}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }


  uploadImageMultipart(data: any) {
    return this.http.post(this.baseUrl_IMAGE + `${API.IMAGE_PROPERTY_MULTIPART}`, data)
      .pipe(map(res => res),
        catchError(this.authService.handleError)
      );
  }

  deleteImageApi(data: any) {
    // return this.http.post(this.baseUrl_IMAGE + `${API.DELETE_IMAGE}`, data)
    let staticAPI = "https://api2.savemax.com/imageservice/deleteFile"
    return this.http.post(staticAPI, data)
      .pipe(map(res => res),
        catchError(this.authService.handleError)
      );
  }

  tagsListApi(data: any) {
    return this.http.post(this.baseUrl_ADMIN + `${API.TAGS_lIST}`, data)
      .pipe(map(res => res),
        catchError(this.authService.handleError)
      );
  }

  getCategoryData(data: any) {
    return this.http.post(this.baseUrl + `${API.GET_FILTERED_DATA}`, data)
      .pipe(map(res => res),
        catchError(this.authService.handleError)
      );
  }
}

