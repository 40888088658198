import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/http-interceptors/authentication/auth.service';
import { ENVIRONMENT, API } from 'src/app/common/restapi';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModerationService {
  private baseUrl_USER: string;
  baseUrl_ADMIN: string;
  headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.baseUrl_USER = ENVIRONMENT.BASEURL_USER;
    this.baseUrl_ADMIN = ENVIRONMENT.BASEURL_ADMIN;
  }

  getModerationListing(data: any) {
    return this.http.post(this.baseUrl_USER + `${API.LIST_MODERATION}`, data).pipe(
      //return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.LIST_MODERATION}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );

  }

  getBeds(data: any) {
    return this.http.post(this.baseUrl_ADMIN + `${API.PROPERTY_GET_BEDS}`, data).pipe(
      //return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.LIST_MODERATION}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );

  }
  getGarage(data: any) {
    return this.http.post(this.baseUrl_ADMIN + `${API.PROPERTY_GET_GARAGE}`, data).pipe(
      //return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.LIST_MODERATION}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );

  }
  getBath(data: any) {
    return this.http.post(this.baseUrl_ADMIN + `${API.PROPERTY_GET_BATH}`, data).pipe(
      //return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.LIST_MODERATION}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );

  }

  getComunityApi(data: any) {
    this.headers = this.authService.setHeader();
    return this.http.post(this.baseUrl_USER + `${API.LIST_OF_COMUNITY}`, data)
      .pipe(map(res => res), catchError(this.authService.handleError)
      );
  }

  changeStatus(data: any) {
    return this.http.post(this.baseUrl_USER + `${API.CHANGE_STATUS}`, data).pipe(
      //return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.CHANGE_STATUS}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );

  }
  changeStatus_2(data: any) {
    return this.http.post(this.baseUrl_USER + `${API.CHANGE_STATUS_NEW}`, data).pipe(
      //return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.CHANGE_STATUS}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );

  }

  changesUpdateStatus(data: any) {
    return this.http.post(this.baseUrl_USER + `${API.UPDATE_STATUS}`, data).pipe(
      //return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.CHANGE_STATUS}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );

  }

  changeStatusInBluck(data: any) {
    return this.http.post(this.baseUrl_USER + `${API.CHANGE_STATUS_ALL}`, data).pipe(
      //return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.CHANGE_STATUS_ALL}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );

  }

  updateSettings(data: any) {
    return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.MODERATION_SETTINGS}`, data).pipe(
      //return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.CHANGE_STATUS_ALL}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );

  }

  getSettings(data: any) {
    return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.LIST_SOCIAL_SHARING}`, data).pipe(
      //return this.http.post(ENVIRONMENT.BASEURL_LOCAL + `${API.CHANGE_STATUS_ALL}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );

  }
}
